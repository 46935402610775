import React, { Component } from 'react';
import { appsettings } from '../../appsettings.js';
import { common } from '../common/common.js';
import { LoadingSpinner } from '../common/spinner.js';
import '../customer/cc.css';
import './invoice.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export class PayInvoiceDetails extends Component {
    constructor(props) {
        super(props);

        this.invoiceID = "";
        this.tokenInfo = "";
        this.redirect_url ="";
        this.state = {
            isLoading:false,
            isCustomerLoaded:false,
            isInvoiceLoaded:false,
            showTextBox:false,
            loaderText: "",
            validRequest: true,
            invoiceNumber: "",
            customerPO :"",
            invoiceDate :"",
            dueDate :"",
            invoiceAmt :0.00,
            invoiceAmtEntered :0.00,
            invoiceBalAmt :0.00,
            customerTotalAcntBal :0.00,
            customerAccount :"",
            customerName :"",
            companyName :"",
            customer: {},
            supportedCardTypes:[],
            supportedCardTypeImages:[],
            countryList: [],
            creditCards: [],
            modalTitle: "",
            showLogo: true,
            showLogoAddress: false,
            companyLogo: "",
            companyAddress: "",
            displayYears: 25,
            creditCardID: "",
            cardType: "",
            cardNumber: "",
            expiryMonth: -1,
            expiryYear: -1,
            defaultCard: true,
            firstName: "",
            lastName: "",
            address: "",
            city: "",
            state: "",
            zipCode: "",
            countryCode: "",
            countryName: "",
            countryID: "",
            cardCode: "",
            showAIMSAlert: false,
            aimsAlertText: "",
            aimsAlertHTML: "",
            aimsAlertHeaderClass: "bg-danger text-white aimsAlertHeader",
            confirmationCallBackMethod:"",
            showConfirmationModal:false,
            confirmationModalText: "",
            currentCCID: 0,
            currencySymbol :"",
            selectedCard:"",
            isInvoiceOpen:false,
            print_url:"",
            download_url:"",
            isLargeScreen: window.innerWidth > 576
        }
        this.enforceMaxOnCardNumber = this.enforceMaxOnCardNumber.bind(this);
        this.enforceMaxOnCardCode = this.enforceMaxOnCardCode.bind(this);  
    }

    changeCardNumber = (e) => {
        this.setState({ cardNumber: e.target.value });
    }
    changeExpiryMonth = (e) => {
        this.setState({ expiryMonth: e.target.value });
    }
    changeExpiryYear = (e) => {
        this.setState({ expiryYear: e.target.value });
    }
    changeDefaultCard = (e) => {
        this.setState({ defaultCard: e.target.value });
    }
    changeFirstName = (e) => {
        this.setState({ firstName: e.target.value });
    }
    changeLastName = (e) => {
        this.setState({ lastName: e.target.value });
    }
    changeAddress = (e) => {
        this.setState({ address: e.target.value });
    }
    changeCity = (e) => {
        this.setState({ city: e.target.value });
    }
    changeState = (e) => {
        this.setState({ state: e.target.value });
    }
    changeZipCode = (e) => {
        this.setState({ zipCode: e.target.value });
    }
    changeCountry = (e) => {
        let obj = this.state.countryList.find(c => c.countryID == e.target.value);
        this.setState({ countryID: e.target.value });
        this.setState({ countryCode: obj.countryCode });
        this.setState({ countryName: obj.countryName });
    }
    changeCardCode = (e) => {
        this.setState({ cardCode: e.target.value });
    }

    handleCardSelection= (credId) => {
        this.setState({ selectedCard: credId});
    }

    handleAccordionClick = () => {
    this.setState({ isInvoiceOpen: !this.state.isInvoiceOpen });
    };

    showLoader(showLoader, LoaderText){
        this.setState({ isLoading: showLoader });
        this.setState({ loaderText: LoaderText });
    }
    
    componentDidMount() {
        localStorage.setItem('receiptData', '');
        var url = window.location.href;
        var strinvoiceID =  "";
        var strAdditionalInfo = "";
        if (url.indexOf(appsettings.constants.invoice_base_param1) > 0 && url.indexOf(appsettings.constants.invoice_base_param2) > 0)
        {
            strinvoiceID = url.substring(url.indexOf(appsettings.constants.invoice_base_param1) + appsettings.constants.invoice_base_param1.length, url.indexOf(appsettings.constants.invoice_base_param2));
            strAdditionalInfo = url.substring(url.indexOf(appsettings.constants.invoice_base_param2) + appsettings.constants.invoice_base_param2.length);
            this.invoiceID= strinvoiceID;
            this.tokenInfo= strAdditionalInfo.split("/")[0];
            this.redirect_url = url;
        }
        if (this.invoiceID == undefined || this.tokenInfo == undefined || this.invoiceID == "" || this.tokenInfo == "")
        {
            this.setState({ validRequest: false });
            this.AIMSAlert("Attention Required!", "Invalid request. Please use the same url received to the email.", false);
           return;
        }

        var cTypes = common.getSessionItem("SupportedCardTypes");
        if (cTypes == null)
        {
            this.fetchCustomerSupportedCardTypes();
        }
        else {
            cTypes = JSON.parse(cTypes);
            this.bindSupportedCardTypes(cTypes.supportedCardTypes);
        }
        this.fetchCountries();
        this.refreshList(); 
        this.fetchinvoicedetails(); 
        window.addEventListener('resize', this.handleResize);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
      }
      handleResize = () => {
        this.setState({
          isLargeScreen: window.innerWidth > 576 
        });
      };
    fetchinvoicedetails(){
        this.showLoader(true,"Fetching details.. Please wait..." );
        this.setState({ isInvoiceLoaded: true });
          var invoiceurl = appsettings.routes.PayInvoice_Base_URL + '/' + appsettings.constants.invoice_base_url_version + appsettings.constants.invoice_base_param1 + this.invoiceID + appsettings.constants.invoice_base_param2 + this.tokenInfo;
          fetch(invoiceurl, {
               method: 'GET',
               headers: {
                   'Content-type': 'application/json',
               }
           })
           .then(response => response.json())
           .then(data => {
              this.showLoader(false,"Fetching details.. Please wait..." );  
              this.setState({ isInvoiceLoaded: false });
               if (data !== undefined)
               {
                   if (data.ErrorMessage != null && data.ErrorMessage !== undefined && data.ErrorMessage !== "")
                   {
                       this.AIMSAlert("Attention Required!", data.ErrorMessage, false);
                       this.setState({ validRequest: false });
                   }
                   else
                   {
                    if(data.viewPayLink)
                    {
                      this.bindInvoiceDetails(data);
                    }
                    else
                    {
                      this.setState({ validRequest: false });
                      this.AIMSAlert("Attention Required!", "This is a factored invoice. Please submit your payment directly to the factor, as noted in the invoice’s 'REMIT TO' details.", false);
                      return;
                    }
                   }
               }
           })
           .catch(error => {
              this.showLoader(false,"" );
              this.setState({ isInvoiceLoaded: false });
           });
       }
       bindInvoiceDetails(data) {
           if (data != undefined)
           {
               this.setState({ invoiceNumber: data.invoice });
               this.setState({ customerPO: data.customerPurchaseOrder });
               this.setState({ invoiceDate: data.invoiceDate });
               this.setState({ dueDate: data.dueDate });
               this.setState({ invoiceAmt: data.invoiceAmount });
               this.setState({ invoiceAmtEntered: data.balance.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }) });
               this.setState({ invoiceBalAmt: data.balance });
               this.setState({ customerTotalAcntBal: data.totalAccountBalance });
               this.setState({ customerAccount: data.customerAccount });
               this.setState({ customerName: data.customerName });
               this.setState({ companyName: data.companyName });
               this.setState({ currencySymbol: data.currencySymbol });
               this.setState({ print_url: data.print_invoice_url });
               this.setState({ download_url: data.download_invoice_url });
               
           }
       }
    bindSupportedCardTypes(data) {
        if (data != undefined && data.length > 0)
        {
            let imgArray = new Array();
            for (let i=0;i<data.length;i++) {
                let imgSrc = common.getCardImage(data[i]);
                if (imgSrc != "")
                imgArray.push(imgSrc);
            }
            this.setState({ supportedCardTypes: data });
            this.setState({ supportedCardTypeImages: imgArray });
            common.setSessionItem("SupportedCardTypes", JSON.stringify({ supportedCardTypes: data }));
        }
    }
    fetchCustomerSupportedCardTypes() {
        fetch(appsettings.routes.CreditCards_URL + '/v1.0/customer/' + this.invoiceID + '/requestpaymentdetails/merchantsupportedcardtypes/' + this.tokenInfo + '/1', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data != undefined && data.length > 0)
            {
                this.bindSupportedCardTypes(data);
            }
        })
        .catch(error => {
            console.log(error);
        });
    }
    fetchCountries() {
        fetch(appsettings.routes.CreditCards_URL + '/v1.0/customer/' + this.invoiceID + '/country/' + this.tokenInfo + '/1', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data != undefined)
            {
                if (data.ErrorMessage != null && data.ErrorMessage != undefined && data.ErrorMessage != "")
                {
                    this.AIMSAlert("Attention Required!", data.ErrorMessage, false);
                    this.setState({ validRequest: false });
                }
                else
                {
                    this.setState({ countryList: data });
                }                
            }
        })
        .catch(error => {
            console.log(error);
        });
    }
    refreshList() {
        this.showLoader(true,"Fetching details.. Please wait..." );
        this.setState({ isCustomerLoaded: true });
        fetch(appsettings.routes.CreditCards_URL + '/v1.0/customer/' + this.invoiceID + '/requestpaymentdetails/' + this.tokenInfo + '/1', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
            }
        })
        .then(response => response.json())
        .then(data => {
            this.showLoader(false,"Fetching details.. Please wait...");
            this.setState({ isCustomerLoaded: false });
            if (data != undefined)
            {
                if (data.ErrorMessage != null && data.ErrorMessage != undefined && data.ErrorMessage != "")
                {
                    this.AIMSAlert("Attention Required!", data.ErrorMessage, false);
                    this.setState({ validRequest: false });
                }
                else
                {
                    if (data.Customer != null)
                    {
                        this.setState({ customer: data.Customer });
                    }
                    if (data.DisplayLogoType == "LOGO")
                    {
                        this.setState({ showLogo: true });
                        this.setState({ showLogoAddress: false });
                        this.setState({ companyLogo: data.ImageSource });
                    }
                    else if (data.DisplayLogoType == "ADDRESS")
                    {
                        this.setState({ showLogo: false });
                        this.setState({ showLogoAddress: true });
                        this.setState({ companyAddress: data.CompanyAddress });
                    }
    
                    var ccList = new Array();
                    var cc= data.CC;
                    if (cc != undefined)
                    {
                        for (let i = 0; i < cc.length; i++)
                        {
                            let ccobj = {
                                creditCardID : cc[i].creditCardID,
                                cardType: cc[i].cardType,
                                cardNumber : cc[i].cardNumber,
                                expiryMonth : cc[i].expiryMonth,
                                expiryYear : cc[i].expiryYear,
                                defaultCard : cc[i].defaultCard,
                                firstName : cc[i].firstName,
                                lastName : cc[i].lastName,
                                address : cc[i].billingAddress.address,
                                city : cc[i].billingAddress.city,
                                state : cc[i].billingAddress.state,
                                zipCode : cc[i].billingAddress.zipCode,
                                countryID : cc[i].billingAddress.country != null ? cc[i].billingAddress.country.countryID : "",
                                countryCode : cc[i].billingAddress.country != null ? cc[i].billingAddress.country.countryCode: "",
                                countryName : cc[i].billingAddress.country != null ? cc[i].billingAddress.country.countryName: "",
                                cardCode : ""
                            };
                            if(ccobj.defaultCard)
                            this.setState({ selectedCard: ccobj.creditCardID });
                            ccList.push(ccobj);
                        }
                    }
                    this.setState({ creditCards: ccList });
                }
            }
        })
        .catch(error => {
            this.showLoader(false,"" );
            this.setState({ isCustomerLoaded: false });
        });
    }
    addClick() {
        this.setState({
            modalTitle: "Add Card",
            creditCardID: "",
            cardType: "",
            cardNumber: "",
            expiryMonth: -1,
            expiryYear: -1,
            firstName: "",
            lastName: "",
            address: "",
            city: "",
            state: "",
            zipCode: "",
            countryID : "",
            countryCode : "",
            countryName : "",
            cardCode: ""
        });
    }
    handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };
    payInvoice = (event,invAmt)  => {
      
      window.addEventListener('beforeunload', this.handleBeforeUnload);
        const invAmtWithoutCommas = invAmt.replace(/,/g, "");
        const invamount = parseFloat(invAmtWithoutCommas);
        
        localStorage.removeItem('receiptData');
        if(invamount>0 && this.state.selectedCard != null && this.state.selectedCard != "")
        {
            var createPayInvObj = {
                paymentAmount: invamount,
                CreditCardGuid: this.state.selectedCard
            };
            
            this.showLoader(true,"Invoice Payment is in Process.. Please wait..." );
            var payInvoiceurl = appsettings.routes.PayInvoice_Base_URL + '/' + appsettings.constants.invoice_base_url_version + appsettings.constants.invoice_base_param1 + this.invoiceID + appsettings.constants.invoice_base_param2 + this.tokenInfo;
            fetch(payInvoiceurl, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(createPayInvObj)
            })
            .then(res => res.json())
            .then((result) => {
                this.showLoader(false,"" );
                window.removeEventListener('beforeunload', this.handleBeforeUnload);
                if (result.ErrorMessage != null && result.ErrorMessage != undefined && result.ErrorMessage != "")
                    this.AIMSAlert("Attention Required!", "Payment Failed. " + result.ErrorMessage, false);
                else
                {
                    if(result.transactionId != null)
                      this.openPaymentReceipt(result);
                    else
                      this.AIMSAlert("Attention Required!", "Payment failed due to an unexpected error. Please try again.", false);
                    //  this.fetchinvoicedetails();
                }
            }, (error) => {
                this.showLoader(false,"" );
                window.removeEventListener('beforeunload', this.handleBeforeUnload);
                this.AIMSAlert("Attention Required!", 'Payment Failed. ' + error, false);
            })
        }
        else
        {
            this.AIMSAlert("Attention Required!", "Invalid Amount/No CreditCard selected.", false);
        }      
    }
    openPaymentReceipt = (data) => {
       
       // const features = "width=1200,height=600,toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,copyhistory=no,resizable=yes,titlebar=no";
        const url = window.location.href + "/paymentreceipt/"+ data.transactionId;
        data.print_url = this.state.print_url;
        data.companyLogo = this.state.companyLogo;
        data.redirect_url = this.redirect_url;
        localStorage.setItem('receiptData', JSON.stringify(data));
        window.location.href = url;
      //  window.location.assign(url)
        window.location.reload();
      //  if(this.redirect_url)
      //    window.location.assign(url)
       // window.open(url);
      //   const receiptpopup = window.open(url, 'PaymentReceipt', features);
      //   if (!receiptpopup || receiptpopup.closed || typeof receiptpopup.closed === 'undefined') {
      //     this.AIMSAlert("Attention Required!", 'Popup blocked. Please allow popups for this site to view receipt.', false);
      // }
    };
    editClick(cc) {
        this.setState({
            modalTitle: "Edit Card",
            creditCardID: cc.creditCardID,
            cardType: cc.cardType,
            cardNumber: cc.cardNumber,
            expiryMonth: cc.expiryMonth,
            expiryYear: cc.expiryYear,
            defaultCard: cc.defaultCard,
            firstName: cc.firstName,
            lastName: cc.lastName,
            address: cc.address,
            city: cc.city,
            state: cc.state,
            zipCode: cc.zipCode,
            countryID : cc.countryID,
            countryCode : cc.countryCode,
            countryName : cc.countryName,
            cardCode: cc.cardCode
        });
    }
    createCC() {
        let valMsg = "";
        if (this.state.cardNumber == "")
            valMsg += "Invalid Card Number <br />";
        if (this.state.expiryMonth == "" || this.state.expiryMonth == "-1")
            valMsg += "Invalid Expiry Month <br />";
        if (this.state.expiryYear == "" || this.state.expiryYear == "-1")
            valMsg += "Invalid Expiry Year <br />";
        if (this.state.firstName == "")
            valMsg += "Invalid First Name <br />";

        if (valMsg != "")
        {
            this.AIMSAlert("Attention Required!", valMsg, false);
            return;
        }
        if (this.state.creditCards.length == 0){
          this.setState({ confirmationCallBackMethod: "saveCreditCard" });
          this.setState({ confirmationModalText: "Please confirm if you would like to update all of your current open orders that are set for credit card terms (CR) to use this card.<br />NOTE: This will not affect any current pre-payments or pre-authorizations for existing orders in your account. This update will only affect new authorizations or charges going forward." });
          this.setState({ showConfirmationModal: true });
        }
        else
        {
            this.createCCCallback(false);
        }
        
    }
    createCCCallback(updateExistingOrder) {
        
      var createCCObj = {
          creditCardID: this.state.creditCardID,
          cardNumber: this.state.cardNumber.substring(0, 16),
          expiryMonth: Number(this.state.expiryMonth),
          expiryYear: Number(this.state.expiryYear),
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          billingAddress: {
              address: this.state.address,
              city: this.state.city,
              state: this.state.state,
              zipCode: this.state.zipCode
          }
      };
      if (this.state.countryID != "")
      {
          createCCObj.billingAddress.country = {
              countryID : this.state.countryID,
              countryCode : this.state.countryCode,
              countryName : this.state.countryName
          }
      }

      if (this.state.cardCode != ""){
          createCCObj.cvv = this.state.cardCode.substring(0, 4);
      }
      
      if (this.state.creditCards.length == 0){
          createCCObj.defaultCard = true;
          createCCObj.updateExistingOrder = updateExistingOrder;
      }
      
      this.showLoader(true,"Saving credit card.. Please wait..." );
      fetch(appsettings.routes.CreditCards_URL + '/V1.0/customer/' + this.invoiceID + '/requestpaymentdetails/' + this.tokenInfo + '/1', {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(createCCObj)
      })
      .then(res => res.json())
      .then((result) => {
          this.showLoader(false,"" );
          if (result.ErrorMessage != null && result.ErrorMessage != undefined && result.ErrorMessage != "")
              this.AIMSAlert("Attention Required!", "Failed to save. " + result.ErrorMessage, false);
          else
          {
              document.getElementById("btnmodalclose").click();
              this.refreshList();
          }
      }, (error) => {
          this.showLoader(false,"" );
          this.AIMSAlert("Attention Required!", 'Failed to save. ' + error, false);
      })
  }
    updateCC() { 
        let valMsg = "";       
        if (this.state.expiryMonth == "" || this.state.expiryMonth == "-1")
            valMsg += "Invalid Expiry Month <br />";
        if (this.state.expiryYear == "" || this.state.expiryYear == "-1")
            valMsg += "Invalid Expiry Year <br />";
        if (this.state.firstName == "")
            valMsg += "Invalid First Name <br />";

        if (valMsg != "")
        {
            this.AIMSAlert("Attention Required!", valMsg, false);
            return;
        }
        var updateCCObj = {
            creditCardID: this.state.creditCardID,
            cardType: this.state.cardType,
            cardNumber: this.state.cardNumber.substring(0, 16),
            expiryMonth: Number(this.state.expiryMonth),
            expiryYear: Number(this.state.expiryYear),
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            billingAddress: {
                address: this.state.address,
                city: this.state.city,
                state: this.state.state,
                zipCode: this.state.zipCode
            }
        };
        if (this.state.countryID != "")
        {
            updateCCObj.billingAddress.country = {
                countryID : this.state.countryID,
                countryCode : this.state.countryCode,
                countryName : this.state.countryName
            }
        }

        if (this.state.cardCode != ""){
            updateCCObj.cvv = this.state.cardCode.substring(0, 4);
        }
        this.showLoader(true,"Updating credit card.. Please wait..." );
        fetch(appsettings.routes.CreditCards_URL + '/V1.0/customer/' + this.invoiceID + '/requestpaymentdetails/' + updateCCObj.creditCardID + '/' + this.tokenInfo + '/1', {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updateCCObj)
        })
        .then(res => res.json())
        .then((result) => {
            this.showLoader(false,"" );
            if (result.ErrorMessage != null && result.ErrorMessage != undefined && result.ErrorMessage != "")
                this.AIMSAlert("Attention Required!", "Failed to update. " + result.ErrorMessage, false);
            else
            {
                document.getElementById("btnmodalclose").click();
                this.refreshList();
            }
        }, (error) => {
            this.showLoader(false,"" );
            this.AIMSAlert("Attention Required!", 'Failed to update. ' + error, false);
        })
    }
    deleteCC(ccid) {
        this.setState({ currentCCID: ccid });
        this.setState({ confirmationCallBackMethod: "delete" });
        this.setState({ confirmationModalText: "Are you sure you want to delete?" });
        this.setState({ showConfirmationModal: true });
    }
    deleteCCCallBack() {
        
        let ccId = this.state.currentCCID;
        this.showLoader(true,"Deleting credit card.. Please wait..." );
        fetch(appsettings.routes.CreditCards_URL + '/V1.0/customer/' + this.invoiceID + '/requestpaymentdetails/' + ccId + '/' + this.tokenInfo + '/1', {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then((result) => {
            this.showLoader(false,"" );
            if (result.ErrorMessage != null && result.ErrorMessage != undefined && result.ErrorMessage != "")
                this.AIMSAlert("Attention Required!", "Failed to delete. " + result.ErrorMessage, false);
            else
            {
                this.refreshList();
            }
        }, (error) => {
            this.showLoader(false,"" );
            this.AIMSAlert("Attention Required!", 'Failed to delete. ' + error, false);
        })
    }
    setAsDefaultCC(ccid) {
        
        this.setState({ currentCCID: ccid });
        this.setState({ confirmationCallBackMethod: "setasdefault" });
        this.setState({ confirmationModalText: "Please confirm if you would like to update all of your current open orders that are set for credit card terms (CR) to use this card.<br />NOTE: This will not affect any current pre-payments or pre-authorizations for existing orders in your account. This update will only affect new authorizations or charges going forward." });
        this.setState({ showConfirmationModal: true });
    }
    setAsDefaultCCCallBack(updateExistingOrder) {
        
        let ccId = this.state.currentCCID;
        this.showLoader(true,"Setting credit card as default.. Please wait..." );
        var objSetAsDefault = {
            updateExistingOrders: updateExistingOrder,
        };
        fetch(appsettings.routes.CreditCards_URL + '/V1.0/customer/' + this.invoiceID + '/requestpaymentdetails/setasdefault/' + ccId + '/' + this.tokenInfo + '/1', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(objSetAsDefault)
        })
        .then(res => res.json())
        .then((result) => {
            this.showLoader(false,"" );
            if (result.ErrorMessage != null && result.ErrorMessage != undefined && result.ErrorMessage != "")
                this.AIMSAlert("Attention Required!", "Failed to set card as default. " + result.ErrorMessage, false);
            else
            {
                this.refreshList();
            }
        }, (error) => {
            this.showLoader(false,"" );
            this.AIMSAlert("Attention Required!", 'Failed to set card as default. ' + error, false);
        })
    }
    enforceMaxOnCardNumber(el) {
        if (el.target.value != "") {
          if (parseInt(el.target.value) > parseInt(el.target.max)) {
            el.target.value = el.target.value.substring(0, 16);
            this.setState({ cardNumber: el.target.value });
          }
        }
    }
    enforceMaxOnCardCode(el) {
        if (el.target.value != "") {
          if (parseInt(el.target.value) > parseInt(el.target.max)) {
            el.target.value = el.target.value.substring(0, 4);
            this.setState({ cardCode: el.target.value });
          }
        }
    }
    invoiceClick() {
        window.open(this.state.print_url, '_blank');
    }
    invoiceDownloadClick(downloadtype) {
      if(downloadtype == 'download')
      {
          this.showLoader(true,"Downloading invoice.. Please wait..." );
      }
      else if(downloadtype == 'print')
      {
          this.showLoader(true,"Printing invoice.. Please wait..." );
      }
      var downloadInvoiceurl = appsettings.routes.PayInvoice_Base_URL + this.state.download_url
      fetch(downloadInvoiceurl, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
      })
      .then(res => res.json())
      .then((result) => {
          this.showLoader(false,"" );
          if (result.ErrorMessage != null && result.ErrorMessage != undefined && result.ErrorMessage != "")
              this.AIMSAlert("Attention Required!", "Download Failed. " + result.ErrorMessage, false);
          else
          {
              if(result.fileContent != null)
              {
                  if(downloadtype == 'download')
                  {
                      this.downloadInvoice(result);
                  }
                  else if(downloadtype == 'print')
                  {
                      this.printInvoice(result);
                  }
              }
          }
      }, (error) => {
          this.showLoader(false,"" );
          this.AIMSAlert("Attention Required!", 'Download Failed. ' + error, false);
      })
  }
  downloadInvoice(filestream) {
      const base64String = filestream.fileContent; 
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filestream.fileName +'.pdf';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);

  }

  printInvoice(filestream) {
      const base64String = filestream.fileContent;
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      const url = URL.createObjectURL(blob);

      const newWindow = window.open(url, '_blank');
      if (newWindow) {
          newWindow.onload = function() {
              newWindow.print();
          };
      } else {
          this.AIMSAlert("Attention Required!", 'Popup blocked. Please allow popups for this site and try again.', false);
      }

  }
    EnableTextBox() {
        this.setState({ showTextBox: true });
    }
    handleKeyDown = (e) => {
      const { selectionStart, selectionEnd } = e.target;
      const currentValue = e.target.value;
  
      if ((e.key === 'Backspace' || e.key === 'Delete'|| e.key === '.') && currentValue.includes('.')) {
        const decimalIndex = currentValue.indexOf('.');
  
        if (
          (e.key === 'Backspace' && selectionStart === decimalIndex + 1 && selectionStart === selectionEnd) ||
          (e.key === 'Delete' && selectionStart === decimalIndex && selectionStart === selectionEnd) || e.key === '.'
        ) {
          e.preventDefault();
        }
      }
    };
  InvoiceAmtChange = (event) => {
        const input = event.target;
        const inputValue = input.value;
        const selectionstart = input.selectionStart;
        const selectionend = input.selectionEnd;

        let numericValue = inputValue.replace(/[^0-9.]/g, '');
        if(numericValue == '')
        {
          numericValue='0.00';
        }
        const maxDigits = 12; 
        if (numericValue.length > maxDigits) {
            return
        }
        const formattedValue = this.formatToTwoDecimalPlaces(numericValue);

        const diff = formattedValue.length - inputValue.length;
        const newSelectionStart = Math.max(0, selectionstart + diff);
        const newSelectionEnd = Math.max(0, selectionend + diff);
        this.setState({ invoiceAmtEntered: formattedValue });
        const decimalIndex = inputValue.indexOf('.');
        // if (decimalIndex !== -1 && inputValue.length==selectionstart && inputValue.length - decimalIndex === 4 && !isNaN(parseInt(inputValue[inputValue.length - 1]))) {
        //     const newValue = formattedValue.slice(0, -1) + inputValue[inputValue.length - 1];
        //         this.setState({ invoiceAmtEntered: newValue }, () => {
        //             input.setSelectionRange(newSelectionStart, newSelectionEnd);
        //         });
        // }
        if (decimalIndex == -1) {
              this.setState({ invoiceAmtEntered: formattedValue }, () => {
                  input.setSelectionRange(1, 1);
              });
        }
        else if (inputValue.length - decimalIndex === 3) {
          this.setState({ invoiceAmtEntered: formattedValue }, () => {
              input.setSelectionRange(newSelectionStart, newSelectionEnd);
          });
       }
        else {
            this.setState({ invoiceAmtEntered: formattedValue }, () => {
                input.setSelectionRange(selectionstart, selectionend);
            });
        }
    };
    formatToTwoDecimalPlaces = (value) => {
        const parts = value.split('.');
        let integerPart = parts[0];
        let decimalPart = parts[1] || '';

        if (decimalPart.length > 2) {
            decimalPart = decimalPart.substring(0, 2);
        } else if (decimalPart.length === 1) {
            decimalPart += '0'; 
        }
        let formattedValue = parseFloat(integerPart + '.' + decimalPart).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    
        if (formattedValue.endsWith('.')) {
            formattedValue += '00';
        }
    
        return formattedValue;
    };
    AIMSAlert(headerText, bodyHTML, isSuccess) {
        this.setState({ showAIMSAlert: true });
        this.setState({ aimsAlertText: headerText });
        this.setState({ aimsAlertHTML: bodyHTML });
        if(isSuccess == true)
            this.setState({ aimsAlertHeaderClass: "bg-success text-white aimsAlertHeader" });        
        else
            this.setState({ aimsAlertHeaderClass: "bg-danger text-white aimsAlertHeader" });        
    }    
    confirmationYes = (e) => {
        this.setState({ showConfirmationModal: false });
        let callBackMethod = this.state.confirmationCallBackMethod;
        switch (callBackMethod) {
            case "delete":
                this.deleteCCCallBack();
                break;
            case "setasdefault":
                this.setAsDefaultCCCallBack(true);
                break;
            case "saveCreditCard":
                this.createCCCallback(true);
                break;
        }
    }
    confirmationNo = (e) => {
        this.setState({ showConfirmationModal: false });
        let callBackMethod = this.state.confirmationCallBackMethod;
        switch (callBackMethod) {
            case "setasdefault":
                this.setAsDefaultCCCallBack(false);
                break;
            case "saveCreditCard":
                this.createCCCallback(false);
                break;
        }
    }
    
    render() {
        const {
            isLoading,
            isCustomerLoaded,
            isInvoiceLoaded,
            showTextBox,
            loaderText,
            countryList,
            supportedCardTypeImages,
            validRequest,
            showLogo,
            showLogoAddress,
            companyLogo,
            companyAddress,
            creditCards,
            modalTitle,
            displayYears,
            creditCardID,
            cardType,
            cardNumber,
            expiryMonth,
            expiryYear,
            defaultCard,
            firstName,
            lastName,
            address,
            city,
            state,
            zipCode,
            countryCode,
            countryName,
            countryID,
            cardCode,
            showAIMSAlert,
            aimsAlertText,
            aimsAlertHTML,
            aimsAlertHeaderClass,
            confirmationModalText,
            currentCCID,
            showConfirmationModal,
            invoiceNumber,
            customerPO ,
            invoiceDate ,
            dueDate ,
            invoiceAmt,
            invoiceAmtEntered,
            invoiceBalAmt,
            customerTotalAcntBal,
            customerAccount ,
            customerName ,
            companyName ,
            customer,
            currencySymbol,
            selectedCard,
            isInvoiceOpen,
            print_url,
            download_url,
            isLargeScreen 
        } = this.state;

        let currentMonth = new Date().getMonth() + 1;
        let currentYear = new Date().getFullYear();
        let monthDDItems = [];
        let monthsName = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        monthDDItems.push(<option value="-1">Select Month...</option>);
        for (var i = 0; i < 12; i++) {
            monthDDItems.push(<option value={i+1}>{monthsName[i]}</option>);
        }

        let yearDDItems = [];
        yearDDItems.push(<option value="-1">Select Year...</option>);
        for (var i = 0; i < displayYears; i++) {
            yearDDItems.push(<option value={currentYear + i}>{currentYear + i}</option>);
        }
        return (
          <div>
            {isLoading || isCustomerLoaded || isInvoiceLoaded ? (
              <LoadingSpinner loaderText={loaderText} />
            ) : (
              ""
            )}
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="container-fluid">
                <div className="ccnavbarcontainer hide-on-small">
                  <div className="ccnavbarlogo">
                    <a  href="#" className={"navbar-brand companylogocontainer "}  id="ccPageLogo">
                      <img src={companyLogo} className="companylogo" />
                    </a>
                  </div>
                  <div className={"ccnavbartitle "} id="ccPageTitle">
                    Invoice Payment
                  </div>
                </div>
              </div>
            </nav>
            <div className="container">
              <div className="viewccDetails">
                <div className="hide-on-large">{companyName}</div>
                <div className="accordion hide-on-large  custom-accordion">
                  <div className="accordion-item">
                    <h2
                      className="accordion-header panelsStayOpen-heading"
                      id="invoicepanelsStayOpen-heading"
                    >
                      <button
                        className={`accordion-button collapsed`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#invoicepanelsStayOpen-collapse"
                        aria-controls="invoicepanelsStayOpen-collapse"
                        aria-expanded="false"
                        onClick={this.handleAccordionClick}
                      >
                        View invoice and more info
                      </button>
                    </h2>
                    <div
                      id="invoicepanelsStayOpen-collapse"
                      className={`accordion-collapse panelsStayOpen-collapse ${
                        isInvoiceOpen ? "show" : "collapse"
                      }`}
                      aria-labelledby="invoicepanelsStayOpen-heading"
                    >
                      <div className="accordion-body">
                        <div className="viewinvoiceDetails">
                          <table className="invoice-table-heading">
                            <tr className="invoice-row">
                              <td className="invoice-column1">
                                <h6>
                                  <small>Invoice</small>
                                </h6>
                              </td>
                              <td className="invoice-column2">
                                <h6>
                                  <a href={print_url} target="_blank">
                                    {invoiceNumber}
                                  </a>
                                </h6>
                              </td>
                            </tr>
                            <tr className="invoice-row">
                              <td className="invoice-column1">
                                <h6>
                                  <small>Purchase Order</small>
                                </h6>
                              </td>
                              <td className="invoice-column2">
                                <h6>{customerPO}</h6>
                              </td>
                            </tr>
                            <tr className="invoice-row">
                              <td className="invoice-column1">
                                <h6>
                                  <small>Invoice Date</small>
                                </h6>
                              </td>
                              <td className="invoice-column2">
                                <h6>{invoiceDate}</h6>
                              </td>
                            </tr>
                            <tr className="invoice-row">
                              <td className="invoice-column1">
                                <h6>
                                  <small>Due Date</small>
                                </h6>
                              </td>
                              <td className="invoice-column2">
                                <h6>{dueDate}</h6>
                              </td>
                            </tr>
                            <tr className="invoice-row">
                              <td className="invoice-column1">
                                <h6>
                                  <small>Invoice Amount</small>
                                </h6>
                              </td>
                              <td className="invoice-column2">
                                <h6>
                                  {currencySymbol}{" "}
                                  {invoiceAmt.toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </h6>
                              </td>
                            </tr>
                            <tr className="invoice-row">
                              <td
                                className="invoice-column1 invoice-column-hr"
                                colSpan={2}
                              >
                                <hr></hr>
                              </td>
                            </tr>
                            <tr className="invoice-row">
                              <td className="invoice-column1">
                                <h6>
                                  <small>Invoice Balance</small>
                                </h6>
                              </td>
                              <td className="invoice-column2">
                                <h6>
                                  {currencySymbol}{" "}
                                  {invoiceBalAmt.toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </h6>
                              </td>
                            </tr>
                            <tr className="invoice-row">
                              <td
                                className="invoice-column1 invoice-column-hr"
                                colSpan={2}
                              >
                                <hr></hr>
                              </td>
                            </tr>
                            <tr className="invoice-row">
                              <td className="invoice-column1">
                                <h6>
                                  <small>Total Account Balance</small>
                                </h6>
                              </td>
                              <td className="invoice-column2">
                                <h6>
                                  {currencySymbol}{" "}
                                  {customerTotalAcntBal.toLocaleString(
                                    "en-US",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </h6>
                              </td>
                            </tr>
                            <tr className="invoice-row">
                              <td
                                className="invoice-column1 invoice-column-hr"
                                colSpan={2}
                              >
                                <hr></hr>
                              </td>
                            </tr>
                            <tr className="invoice-row">
                              <td className="invoice-column1">
                                <h6>
                                  <small>Customer Account</small>
                                </h6>
                              </td>
                              <td className="invoice-column2">
                                <h6>{customerAccount}</h6>
                              </td>
                            </tr>
                            <tr className="invoice-row">
                              <td className="invoice-column1">
                                <h6>
                                  <small>Customer Name</small>
                                </h6>
                              </td>
                              <td className="invoice-column2">
                                <h6>{customerName}</h6>
                              </td>
                            </tr>
                           
                          </table>
                        </div>
                        <div className="invoice-button">
                        <button
                                  type="button"
                                  className="btn btn-outline-secondary btn-viewinvoice"
                                  onClick={() => this.invoiceClick()}
                                >
                                  View Invoice
                                </button>
                        </div>
                        <div className="viewmerchantDetails">
                          <table className="invoice-table-heading">
                            <tr className="invoice-row">
                              <td>
                                <h6>Merchant details:</h6>
                              </td>
                            </tr>
                            <tr className="invoice-row">
                              <td>
                                <h6>{companyName}</h6>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="payment-text">PAYMENT AMOUNT</div>
               
                <div className="receipt-column">
                  <div className={(showTextBox ? "hideelement" : "showelement")}>
                    <h4>{currencySymbol}  {invoiceAmtEntered}  <button type="button" className={(invoiceBalAmt > 0 ? "btn btn-link  payment-text" : "hideelement")} onClick={() => this.EnableTextBox()}                      >
                    Edit amount
                      </button></h4>
                  </div>

                  <div className={!showTextBox ? "hideelement" : "showelement"}>
                  <h4>{currencySymbol}  <input type="text" className="text-box " value={invoiceAmtEntered} onChange={this.InvoiceAmtChange} onKeyDown={this.handleKeyDown}/> <button type="button" className={(invoiceBalAmt > 0 ? "btn btn-link  payment-text" : "hideelement")} onClick={() => this.EnableTextBox()}                      >
                    Edit amount
                      </button></h4>
                  </div>

                </div>
                <div
                  className={
                    isInvoiceOpen && !isLargeScreen
                      ? "hideelement"
                      : "showelement"
                  }
                >
                  <div
                    id="ccnolist"
                    className={
                      "py-3 " +
                      (creditCards.length == 0 ? "showelement" : "hideelement")
                    }
                  >
                    <h5>No cards available</h5>
                  </div>
                  <div
                    id="ccelementlist"
                    className={
                      creditCards.length > 0 ? "showelement" : "hideelement"
                    }
                  >
                    <div className="row invoice-table-heading">
                      <div className="col-6 col-sm-4 col-md-5 col-lg-6 invoicecclist-table-heading">
                        <h6>Your Saved Cards</h6>
                      </div>
                      <div className="col-2 col-sm-4 col-md-3 col-lg-2 invoicecclist-table-heading">
                        <h6>Expires</h6>
                      </div>
                      <div className="col-4 col-sm-4 col-md-3 col-lg-4 invoicecclist-table-heading">
                        <h6>Default Card</h6>
                      </div>
                    </div>
                    <div className="accordion">
                      {creditCards.map((cc, index) => (
                        <div
                          className="accordion-item mb-2 cclist-item"
                          key={cc.creditCardID}
                        >
                          <h2
                            className="accordion-header invcclist-header"
                            id={`panelsStayOpen-heading${index + 1}`}
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#panelsStayOpen-collapse${
                                index + 1
                              }`}
                              aria-expanded="false"
                              aria-controls={`panelsStayOpen-collapse${
                                index + 1
                              }`}
                            >
                              <div className="invoice-table-heading">
                                <div className="row" key={index}>
                                  <div className="col-1 invoicecclist-header">
                                    <input
                                      type="radio"
                                      name="SelectedCreditCards"
                                      value={cc.creditCardID}
                                      checked={selectedCard === cc.creditCardID}
                                      onChange={() =>
                                        this.handleCardSelection(
                                          cc.creditCardID
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="col-md-2 col-sm-3 col-2 invoicecclist-header">
                                    <img
                                      src={common.getCardImage(cc.cardType)}
                                      className="ccinvimg-header"
                                    />
                                  </div>
                                  <div className="col-3 invoicecclist-header">
                                    <span>{cc.cardNumber}</span>
                                  </div>
                                  <div className="col-3 col-sm-2 invoicecclist-header ">
                                    <span
                                      className={
                                        cc.expiryYear < currentYear ||
                                        (cc.expiryYear == currentYear &&
                                          cc.expiryMonth < currentMonth)
                                          ? "text-danger"
                                          : " "
                                      }
                                    >
                                      {cc.expiryYear < currentYear ||
                                      (cc.expiryYear == currentYear &&
                                        cc.expiryMonth < currentMonth)
                                        ? "Expired "
                                        : ""}
                                      {monthsName[Number(cc.expiryMonth) - 1]}/
                                      {cc.expiryYear}
                                    </span>
                                  </div>
                                  <div className="col-3 invoicecclist-default">
                                    <span>{cc.defaultCard ? "Yes" : ""}</span>
                                  </div>
                                </div>
                              </div>
                            </button>
                          </h2>
                          <div
                            id={`panelsStayOpen-collapse${index + 1}`}
                            className="accordion-collapse collapse"
                            aria-labelledby={`panelsStayOpen-heading${
                              index + 1
                            }`}
                          >
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-md-5 text-start">
                                  <h4 className="ps-2">Name on card:</h4>
                                  <p className="ps-2">{cc.firstName + " " + cc.lastName}</p>
                                </div>
                                <div className="col-md-7 text-start">
                                  <h4 className="ps-2">Billing Address:</h4>
                                  {cc.address != "" ||
                                  cc.city != "" ||
                                  (cc.state != "" &&
                                    cc.countryName != "" &&
                                    cc.zipCode != "") ? (
                                    <div className="ps-2">
                                      <p className="mb-1 ">{cc.address}</p>
                                      <p className="mb-1 ">{cc.city}</p>
                                      <p className="mb-1 ">{cc.state}</p>
                                      <p className="mb-1 ">{cc.countryName}</p>
                                      <p className="mb-1 ">{cc.zipCode}</p>
                                    </div>
                                  ) : (
                                    <div className="ps-2">
                                      No address details found on card
                                    </div>
                                  )}
                                  <div>
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary m-2  btn-custom"
                                      data-bs-toggle="modal"
                                      data-bs-target="#ccDetailModal"
                                      onClick={() => this.editClick(cc)}
                                    >
                                      Edit
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-outline-danger m-2  btn-custom"
                                      onClick={() =>
                                        this.deleteCC(cc.creditCardID)
                                      }
                                    >
                                      Remove
                                    </button>
                                    <button
                                      type="button"
                                      className={
                                        "btn btn-outline-secondary m-2  btn-custom" +
                                        (cc.defaultCard ? " d-none" : "")
                                      }
                                      onClick={() =>
                                        this.setAsDefaultCC(cc.creditCardID)
                                      }
                                    >
                                      Set as default
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div
                    className={"pt-3 " + (!validRequest ? "hideelement" : "")}
                  >
                    <div className="row">
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-outline-primary m-1 float-start btn-custom"
                          data-bs-toggle="modal"
                          data-bs-target="#ccDetailModal"
                          onClick={() => this.addClick()}
                        >
                          Add New Card
                        </button>
                      </div>
                      <div className="col-6 text-end">
                        {supportedCardTypeImages.map((src, index) => (
                          <img src={src} className="ccinvimg-header" />
                        ))}
                      </div>
                    </div>
                  
                  </div>
                </div>
              
              </div>
              
              <div
                  className="modal fade"
                  id="ccDetailModal"
                  tabIndex="-1"
                  data-bs-backdrop="static"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">{modalTitle}</h5>
                        <button
                          type="button"
                          id="btnmodalclose"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>

                      <div className="modal-body">
                        <h4 className="text-start mb-2">Card details</h4>
                        <div
                          className={
                            "mb-2 row " +
                            (creditCardID != "" ? "hideelement" : "")
                          }
                        >
                          <label
                            htmlFor="supportedlogosblock"
                            className="col-sm-3 col-form-label text-start"
                          >
                            Supported Cards:{creditCardID}
                          </label>
                          <div
                            className="col-sm-9 text-start"
                            id="supportedlogosblock"
                          >
                            {supportedCardTypeImages.map((src, index) => (
                              <img src={src} className="invoiceccimg-icon" />
                            ))}
                          </div>
                        </div>
                        <div className="mb-2 row">
                          <label
                            htmlFor="txtCardNumber"
                            className="col-sm-3 col-form-label text-start"
                          >
                            Card Number:{" "}
                          </label>
                          <div
                            className={
                              creditCardID != "" ? "col-8" : "col-9"
                            }
                          >
                            <input
                              type={creditCardID != "" ? "text" : "number"}
                              className="form-control"
                              id="txtCardNumber"
                              max="9999999999999999"
                              onKeyUp={this.enforceMaxOnCardNumber}
                              disabled={creditCardID != "" ? "disabled" : ""}
                              value={cardNumber}
                              onChange={this.changeCardNumber}
                            />
                          </div>
                          <div
                            className={
                              creditCardID != ""
                                ? "col-1 ps-0"
                                : "hideelement"
                            }
                          >
                            <img
                              src={common.getCardImage(cardType)}
                              className="invoiceccimg-icon"
                            />
                          </div>
                        </div>
                        <div className="mb-2 row">
                          <label
                            htmlFor="txtFirstName"
                            className="col-sm-3 col-form-label text-start"
                          >
                            First Name:{" "}
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              id="txtFirstName"
                              maxLength="40"
                              value={firstName}
                              onChange={this.changeFirstName}
                            />
                          </div>
                        </div>
                        <div className="mb-2 row">
                          <label
                            htmlFor="txtLastName"
                            className="col-sm-3 col-form-label text-start"
                          >
                            Last Name:{" "}
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              id="txtLastName"
                              maxLength="40"
                              value={lastName}
                              onChange={this.changeLastName}
                            />
                          </div>
                        </div>
                        <div className="mb-2 row">
                          <label
                            htmlFor="cmbExpiryMonth"
                            className="col-sm-3 col-form-label  text-start"
                          >
                            Expiry Date:{" "}
                          </label>
                          <div className="col-4 col-sm-3">
                            <select
                              className="form-select"
                              id="cmbExpiryMonth"
                              defaultValue={"-1"}
                              value={expiryMonth}
                              onChange={this.changeExpiryMonth}
                            >
                              {monthDDItems}
                            </select>
                          </div>
                          <div className="col-4 col-sm-3">
                            <select
                              className="form-select"
                              id="cmbExpiryYear"
                              defaultValue={"-1"}
                              value={expiryYear}
                              onChange={this.changeExpiryYear}
                            >
                              {yearDDItems}
                            </select>
                          </div>
                          <label
                            htmlFor="txtCVV"
                            className="col-2 col-sm-1 col-form-label  text-end"
                          >
                            CVV:{" "}
                          </label>
                          <div className="col-2">
                            <input
                              max="9999"
                              type="number"
                              className="form-control"
                              id="txtCVV"
                              value={cardCode}
                              onChange={this.changeCardCode}
                              onKeyUp={this.enforceMaxOnCardCode}
                            />
                          </div>
                        </div>
                        <h4 className="text-start mb-2">Billing Address</h4>
                        <div className="mb-2 row">
                          <label
                            htmlFor="txtAddress"
                            className="col-sm-3 col-form-label  text-start"
                          >
                            Address:{" "}
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              id="txtAddress"
                              maxLength="60"
                              value={address}
                              onChange={this.changeAddress}
                            />
                          </div>
                        </div>
                        <div className="mb-2 row">
                          <label
                            htmlFor="txtCity"
                            className="col-sm-3 col-form-label  text-start"
                          >
                            City/State/Zip:{" "}
                          </label>
                          <div className="col-4 col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              id="txtCity"
                              maxLength="40"
                              value={city}
                              onChange={this.changeCity}
                            />
                          </div>
                          <div className="col-4 col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              id="txtState"
                              maxLength="40"
                              value={state}
                              onChange={this.changeState}
                            />
                          </div>
                          <div className="col-4 col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              id="txtZip"
                              maxLength="20"
                              value={zipCode}
                              onChange={this.changeZipCode}
                            />
                          </div>
                        </div>
                        <div className="mb-2 row">
                          <label
                            htmlFor="txtCountry"
                            className="col-sm-3 col-form-label  text-start"
                          >
                            Country/Country Code:{" "}
                          </label>
                          <div className="col-8 col-sm-6">
                            <select
                              className="form-select"
                              id="txtCountry"
                              defaultValue={"-1"}
                              value={countryID}
                              onChange={this.changeCountry}
                            >
                              <option value="">Select Country...</option>
                              {countryList.map((con, index) => (
                                <option
                                  key={con.countryID}
                                  value={con.countryID}
                                >
                                  {con.countryName}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-4 col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              id="txtCountryCode"
                              value={countryCode}
                            />
                          </div>
                        </div>
                        <div className="ccmodalbuttons">
                          <button
                            type="button"
                            className="btn btn-outline-success float-center"
                            onClick={() =>
                              creditCardID != ""
                                ? this.updateCC()
                                : this.createCC()
                            }
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-secondary float-center"
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div className="viewinvoiceDetails hide-on-small">
                <table className="invoice-table-heading">
                  <tr className="invoice-row">
                    <td colSpan={2}>
                      <h4>{companyName}</h4>
                    </td>
                  </tr>
                  <tr className="invoice-row">
                    <td className="invoice-column1">
                      <h6>
                        <small>Invoice</small>
                      </h6>
                    </td>
                    <td className="invoice-column2">
                      <h6>
                        <a href={print_url} target="_blank">
                          {invoiceNumber}
                        </a>
                      </h6>
                    </td>
                  </tr>
                  <tr className="invoice-row">
                    <td className="invoice-column1">
                      <h6>
                        <small>Purchase Order</small>
                      </h6>
                    </td>
                    <td className="invoice-column2">
                      <h6>{customerPO}</h6>
                    </td>
                  </tr>
                  <tr className="invoice-row">
                    <td className="invoice-column1">
                      <h6>
                        <small>Invoice Date</small>
                      </h6>
                    </td>
                    <td className="invoice-column2">
                      <h6>{invoiceDate}</h6>
                    </td>
                  </tr>
                  <tr className="invoice-row">
                    <td className="invoice-column1">
                      <h6>
                        <small>Due Date</small>
                      </h6>
                    </td>
                    <td className="invoice-column2">
                      <h6>{dueDate}</h6>
                    </td>
                  </tr>
                  <tr className="invoice-row">
                    <td className="invoice-column1">
                      <h6>
                        <small>Invoice Amount</small>
                      </h6>
                    </td>
                    <td className="invoice-column-light">
                      <h6>
                        {currencySymbol}{" "}
                        {invoiceAmt.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </h6>
                    </td>
                  </tr>
                  <tr className="invoice-row">
                    <td className="invoice-column1" colSpan={2}>
                      <hr></hr>
                    </td>
                  </tr>
                  <tr className="invoice-row">
                    <td className="invoice-column1">
                      <h6>
                        <small>Invoice Balance</small>
                      </h6>
                    </td>
                    <td className="invoice-column-light">
                      <h6>
                        {currencySymbol}{" "}
                        {invoiceBalAmt.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </h6>
                    </td>
                  </tr>
                  <tr className="invoice-row">
                    <td className="invoice-column1" colSpan={2}>
                      <hr></hr>
                    </td>
                  </tr>
                  <tr className="invoice-row">
                    <td className="invoice-column1">
                      <h6>
                        <small>Total Account Balance</small>
                      </h6>
                    </td>
                    <td className="invoice-column-light">
                      <h6>
                        {currencySymbol}{" "}
                        {customerTotalAcntBal.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </h6>
                    </td>
                  </tr>
                  <tr className="invoice-row">
                    <td className="invoice-column1" colSpan={2}>
                      <hr></hr>
                    </td>
                  </tr>
                  <tr className="invoice-row">
                    <td className="invoice-column1">
                      <h6>
                        <small>Customer Account</small>
                      </h6>
                    </td>
                    <td className="invoice-column2">
                      <h6>{customerAccount}</h6>
                    </td>
                  </tr>
                  <tr className="invoice-row">
                    <td className="invoice-column1">
                      <h6>
                        <small>Customer Name</small>
                      </h6>
                    </td>
                    <td className="invoice-column2">
                      <h6>{customerName}</h6>
                    </td>
                  </tr>
                  <tr className="invoice-row">
                    <td className="invoice-column1" colSpan={2}>
                      <hr></hr>
                    </td>
                  </tr>
                  <tr className="invoice-row">
                    <td className="invoice-column1">
                      <button type="button" className="btn btn-outline-secondary btn-custom btn-viewinvoice" onClick={() => this.invoiceClick()}                      >
                        View Invoice
                      </button>
                    </td>
                    <td className="invoice-column-light">
                      <button className="button-image" type="button" onClick={() => this.invoiceDownloadClick("download")}>
                           <i className="bi bi-download invoice-download-bold"></i>
                      </button>
                      <button className="button-image" type="button" onClick={() => this.invoiceDownloadClick("print")}>
                        <i className="bi bi-printer invoice-print-bold"></i>   
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
              <div className="viewmerchantDetails hide-on-small">
                <table className="invoice-table-heading">
                  <tr className="invoice-row">
                    <td>
                      <h5>Merchant details:</h5>
                    </td>
                  </tr>
                  <tr className="invoice-row">
                    <td>
                      <h5>{companyName}</h5>
                    </td>
                  </tr>
                </table>
              </div>
              <div className="payinvoicefooter  hide-on-small">
                <i className="bi bi-lock invoice-icons"></i> Secured by{" "}
                <a href="https://aims360.com/" target="_blank">
                  AIMS360
                </a>{" "}
                &{" "}
                <a href="https://www.authorize.net/" target="_blank">
                  Authorize.net
                </a>
              </div>
              <div
                  className={
                    "footer-payment " +
                    (!validRequest ? "hideelement" : "")
                  }
                >
                  <div className={(invoiceBalAmt > 0 ? "row  showelement" : "hideelement")}>
                    <div className="col-12">
                      <button type="button" className="button-text col-8 payinvoice-button-text" onClick={(e) => this.payInvoice(e, invoiceAmtEntered)}>
                      <img src="/images/lock-52.png" className="payinvoice-button-icon" />
                      {/* <i className="bi bi-lock invoice-icons invoice-white"></i>  */}
                      Pay{" "}
                        {currencySymbol} {invoiceAmtEntered}
                      </button>
                    </div>
                  </div>
                  <div className="copyright-text">
                    <i className="bi bi-c-circle invoice-icons"></i>
                    2024 AIMS360. All rights reserved.
                  </div>
                </div>
            </div>
           
            <Modal
              className="aimsAlertModal"
              show={showAIMSAlert}
              onHide={() => this.setState({ showAIMSAlert: false })}
              backdrop="static"
              keyboard={false}
              centered
            >
              <Modal.Header className={aimsAlertHeaderClass} closeButton>
                <Modal.Title>
                  <strong className="mr-auto" id="modalalerttitle">
                    {aimsAlertText}
                  </strong>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body
                dangerouslySetInnerHTML={{ __html: aimsAlertHTML }}
                style={{ textAlign: "left" }}
              ></Modal.Body>
            </Modal>
            <Modal
              show={showConfirmationModal}
              onHide={this.confirmationNo}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>Confirmation!</Modal.Title>
              </Modal.Header>
              <Modal.Body
                dangerouslySetInnerHTML={{ __html: confirmationModalText }}
              ></Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={this.confirmationYes}>
                  Yes
                </Button>
                <Button variant="secondary" onClick={this.confirmationNo}>
                  No
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        );
    }
}